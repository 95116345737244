import React, { useEffect, useRef, useState } from "react";
import { RiShoppingCart2Fill, RiShoppingCart2Line } from "react-icons/ri";
import * as S from "./Slider.styles";

const Slider = (props) => {
  // const steps = props.storedItemsListLength; // @Reg todo - whichever's less 100 or storedItemsListLength
  const maxQuickListLength = 50;
  const steps =
    props.storedItemsListLength >= maxQuickListLength
      ? maxQuickListLength
      : props.storedItemsListLength;

  const step = 1 / `${steps}`;
  const slider = useRef(null);
  const [sliderLeft, setSliderLeft] = useState(null);
  const [sliderRight, setSliderRight] = useState(null);
  const [bubbleX, setBubbleX] = useState(null);
  const wrapperOffset = 40; // should set this dynamically
  useEffect(() => {
    setSliderLeft(slider.current.offsetLeft + wrapperOffset);
    setSliderRight(
      slider.current.offsetLeft + slider.current.offsetWidth + wrapperOffset
    );
  }, []);
  let currentTouch;
  let limitedTouch; // don't move bubble beyond the slider ends
  const updateSliderBubble = (e) => {
    currentTouch = Math.round(e.targetTouches[0].clientX);
    if (currentTouch < sliderLeft) {
      limitedTouch = sliderLeft;
    } else if (currentTouch > sliderRight) {
      limitedTouch = sliderRight;
    } else {
      limitedTouch = currentTouch;
    }
    setBubbleX(limitedTouch - wrapperOffset);
    setBubbleX(currentTouch - wrapperOffset);
    setBubbleX(limitedTouch - wrapperOffset);
  };

  const updateSliderBackground = (value) => {
    const percentage = ((value - 0) / (`${steps}` - 0)) * 100; // Assuming min=0 and max=100
    return `linear-gradient(to right, #039cff ${percentage}%, #e5e5e5 ${percentage}%)`;
  };
  const sliderStyle = {
    background: updateSliderBackground(props.listLength),
  };

  return (
    <div className="text-center relative">
      <S.BubbleWrap
        className="tooltip tooltip-above bottom-10"
        style={{ left: bubbleX ? bubbleX : "50%" }}
      >
        <span className="whitespace-nowrap">
          <b>{props.listLength}</b> item{props.listLength !== 1 && "s"}
          {/* {props.listLength !== 0 &&
            props.storedAvgCost !== 0 &&
            props.storedAvgCost !== Infinity && (
              <>
                {" "}
                ≈ ${Math.ceil((props.storedAvgCost * props.listLength) / 5) * 5}
              </>
            )} */}
        </span>
      </S.BubbleWrap>
      <div className="mt-4 px-4 w-full flex items-center justify-between">
        <RiShoppingCart2Line className="w-6 h-6" />
        <input
          ref={slider}
          type="range"
          id="rangeSlider"
          className="w-full mx-3"
          value={props.listLength}
          // value={intialValue}
          min="0"
          max={steps}
          step={step}
          onChange={props.handleSlider}
          onTouchStart={(e) => {
            updateSliderBubble(e);
          }}
          onTouchMove={(e) => {
            updateSliderBubble(e);
          }}
          style={sliderStyle}
        />
        <RiShoppingCart2Fill className="w-6 h-6" />
      </div>
    </div>
  );
};

export default Slider;
