import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Emoji from "./Emoji";

export default function ModalDeepRemove(props) {
  return (
    <>
      <Transition appear show={props.isOpenDeepRemove} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          // onClose gets called when a click is outside the modal
          // i was using this for closing modals, but doesn't work for this one
          // onClose={props.closeModalDeepRemove}
          // because it was getting triggered after item long press event
          // to solve for close being triggered return an empty function instead
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 modal-bg" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="modal w-full max-w-md transform overflow-hidden bg-[#fdfefb] shadow-xl transition-all">
                  <Dialog.Title as="h2" className="modal-heading">
                    Remove item?
                  </Dialog.Title>
                  <div className="normal-case">
                    {/* Remove from future lists? */}
                    <div className="scale-75 din mb-2 bg-red-50 relative rounded-full border border-red-300 whitespace-nowrap h-[56px] uppercase tracking-widest leading-none py-3 px-5 flex items-center">
                      <div className="overflow-hidden items-center overflow-ellipsis flex grow">
                        <Emoji
                          className="absolute"
                          label={props.nameToDelete}
                        />
                        <div className="ml-8 text-left w-full overflow-hidden overflow-ellipsis">
                          {props.nameToDelete}
                        </div>
                      </div>
                    </div>
                    <div className="normal-case text-left">
                      {/* <p>Remove from this list and exclude from future ones?</p> */}
                      <p>Exclude from Quick Lists.</p>
                    </div>
                  </div>
                  <div className="mt-8 flex space-x-4">
                    <button
                      className="btn btn-secondary w-1/2"
                      onClick={props.closeModalDeepRemove}
                    >
                      Nope
                    </button>
                    <button
                      className="btn w-1/2"
                      onClick={() => {
                        props.closeModalDeepRemove();
                        props.removeFromStorage(props.idToDelete);
                        props.checkFinish();
                      }}
                    >
                      Yep
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
