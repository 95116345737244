import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function ModalBinList(props) {
  return (
    <>
      <Transition appear show={props.isOpenBinList} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={props.closeModalBinList}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 modal-bg" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="modal w-full max-w-md transform overflow-hidden bg-[#fdfefb] shadow-xl transition-all">
                  <Dialog.Title as="h2" className="modal-heading">
                    Bin this list?
                  </Dialog.Title>
                  <div className="mt-8 flex space-x-4">
                    <button
                      className="btn btn-secondary w-1/2"
                      onClick={props.closeModalBinList}
                    >
                      Nah
                    </button>
                    <button
                      className="btn w-1/2"
                      onClick={() => {
                        props.closeModalBinList();
                        props.handleBinList();
                      }}
                    >
                      Yeah
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
