import React from "react";

const Tooltip = ({ children, classNames, style }) => {
  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  );
};

export default Tooltip;
