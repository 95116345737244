import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
// import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

createRoot(document.getElementById("root")).render(<App />);

serviceWorkerRegistration.register();

reportWebVitals();
