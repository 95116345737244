import React from "react";

const Alert = ({ children, classNames, style }) => {
  return (
    <div
      className={`alert z-20 absolute top-[1.75rem] left-1/2 -translate-y-1/2 -translate-x-1/2 ${classNames}`}
      style={style}
    >
      {children}
    </div>
  );
};

export default Alert;
