import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import useLocalStorage from "./useLocalStorage";
import PrettyDate from "./PrettyDate";
import Header from "./Header";
import Footer from "./Footer";
import Splashscreen from "./Splashscreen";
import Item from "./Item";
import TextInput from "./TextInput";
import Chart from "./Chart";
import Info from "./Info";
import Alert from "./Alert";
import ModalDeepRemove from "./ModalDeepRemove";
import ModalBinList from "./ModalBinList";
import ModalCost from "./ModalCost";
import ModalHelp from "./ModalHelp";
import ModalSlider from "./ModalSlider";
import { RiDeleteBinLine, RiPencilFill } from "react-icons/ri";
// import { ReactComponent as MagicWand } from "./images/wand.svg";
import { BsStars } from "react-icons/bs";

function App() {
  // Prevent zoom
  // useEffect - load first so window is available
  React.useEffect(() => {
    window.addEventListener(
      "touchmove",
      function (event) {
        if (event.scale !== 1) {
          event.preventDefault();
          event.stopImmediatePropagation();
        }
      },
      { passive: false }
    );
  }, []);

  // Local Storage
  const [storedItems, setStoredItems] = useLocalStorage("STORED_ITEMS", []);
  const storedItemsListLength = storedItems.length || 0;
  const [storedCosts, setStoredCosts] = useLocalStorage("STORED_COSTS", []);
  const [storedActiveList, setStoredActiveList] = useLocalStorage(
    "STORED_IN_PROGRESS_LIST",
    []
  );
  const isStoredActiveListEmpty = storedActiveList.length === 0;
  const storedActiveListLength = storedActiveList.length || 0;
  // console.log("isStoredActiveListEmpty: ", isStoredActiveListEmpty);

  const [storedUserSettings, setStoredUserSettings] = useLocalStorage(
    "STORED_USER_SETTINGS",
    {
      mockData: false,
      firstVisit: true,
      trackSpend: false,
      // trackPrepopulate: false,
    }
  );

  // Toggle Mock data for dev purposes (provides toggle on Settings page)
  // const showMockData = true;
  const showMockData = false;

  const [storedAvgCost, setStoredAvgCost] = useLocalStorage(
    "STORED_AVG_COST",
    0
  );

  const [appStep, setAppStep] = useState("list");

  const [showSplash, setShowSplash] = useState(true);

  // use state
  const [addSharedFunctionCalled, setAddSharedFunctionCalled] = useState(false);

  // Calculate and store average cost
  useEffect(() => {
    // console.log("storedCosts updated: ", storedCosts);
    const totalCosts = storedCosts.reduce((accumulator, current) => {
      return accumulator + current.cost;
    }, 0);
    const totalItemsPurchased = storedCosts.reduce((accumulator, current) => {
      return accumulator + current.itemsPurchased;
    }, 0);
    // setStoredAvgCost(totalCosts / totalItemsPurchased || 0); // 0 if null
    setStoredAvgCost(
      Math.round((totalCosts / totalItemsPurchased) * 100) / 100 || 0
    ); // 0 if null
    // console.log("storedCosts: ", storedCosts);
    // console.log("totalItemsPurchased: ", totalItemsPurchased); // @Reg – returning 0!!!
  }, [storedCosts]);

  function handleSortByOrder() {
    // First slice() off the most frequently purchased items
    // Then sort() into (descending) by collection order
    const slicedList = storedItems.slice(0, listLength);
    const sortedList = [...slicedList].sort(
      (a, b) => b.avgCollectionOrder - a.avgCollectionOrder
    );
    setStoredActiveList(sortedList);
  }
  // censorship guards the narrative at the expense of the truth
  // Sort stored items by frequency
  // (called whenever a new item is marked complete)
  function orderStoredItemsByFreq(itemsToSortAndAdd) {
    const sortedList = [...itemsToSortAndAdd].sort((a, b) => b.freq - a.freq);
    setStoredItems(sortedList);
  }

  const [count, setCount] = useState(1); // to record the order in which purchased
  const [listLength, setListLength] = useState(
    Math.round(storedItemsListLength / 2) || 0
  );
  const shareableList = sanitisedActiveList(storedActiveList); // For sharing, e.g. ['carrots', 'bananas', 'apples']
  const [inputText, setInputText] = useState("");

  const [addButtonState, setAddButtonState] = useState("fill-[#e9f8fb]"); // invisible
  const handleInputChange = (e) => {
    // console.log(e.target.value.trimStart());
    setInputText(e.target.value.trimStart());
    e.target.value.length === 0
      ? setAddButtonState("fill-[#e9f8fb]")
      : setAddButtonState("fill-[#4599f7]");
  };

  // Share link e.g: http://localhost:3000/apps/blist/?q=%5B%22elijah%22%2C%22is%20%22%2C%22cool%22%5D
  useEffect(() => {
    const queryString = window.location.search || null;
    if (queryString !== null) {
      const params = new URLSearchParams(queryString);
      const objectString = params.get("q");
      const sharedList = JSON.parse(decodeURIComponent(objectString));
      // console.log("sharedList", sharedList);
      setAppStep("list");
      addSharedItems(sharedList);
    }
  }, []);

  const addSharedItems = (sharedList) => {
    let newSharedList = [];
    sharedList.map((item) => {
      return newSharedList.push({
        name: item,
        isDone: false,
        id: uuidv4(),
        key: uuidv4(),
      });
    });
    if (!isStoredActiveListEmpty) {
      // console.log("!isStoredActiveListEmpty");
      // Merge a newly shared list with existing (In Progress) list
      // Exclude duplicates
      const filteredSharedList = newSharedList.filter(
        (newItem) =>
          !storedActiveList.some(
            (existingItem) => existingItem.name === newItem.name
          )
      );
      const mergedList = [...storedActiveList, ...filteredSharedList];
      setStoredActiveList([...mergedList]);
    } else {
      setStoredActiveList([...newSharedList]);
    }
    setIsOpenSlider(false); // close list (Slider or How-tos) modal
    // handleMagicSort(); // this busts... maybe storedActiveList isn't fully set when the function performs?
    setAddSharedFunctionCalled(true);
    resetUrl(); // Remove the query string
  };

  // Run handleMagicSort after shared items are added and merged
  useEffect(() => {
    if (addSharedFunctionCalled) {
      handleMagicSort();
      setAddSharedFunctionCalled(false);
    }
  }, [addSharedFunctionCalled]);

  const addItem = () => {
    if (inputText !== "") {
      const newItemName = inputText.toLowerCase();
      // If a duplicate item is added, we quietly delete the original version
      const duplicateItem = storedActiveList.find(
        (item) => item.name === newItemName
      );
      let newArr;
      if (duplicateItem) {
        newArr = storedActiveList.filter(
          (item) => item.id !== duplicateItem.id
        );
        setStoredActiveList(newArr);
      } else {
        newArr = storedActiveList;
      }
      const newItem = {
        name: newItemName,
        isDone: false,
        id: uuidv4(),
        key: uuidv4(),
      };
      setStoredActiveList([...newArr, newItem]);
    }
    setInputText("");
  };

  const handleScrollList = () => {
    const element = document.getElementById("list-bottom");
    const animateScroll = () => {
      element.scrollIntoView({ behavior: "auto" });
    };
    const animateInterval = setInterval(() => {
      animateScroll();
    }, 17);
    setTimeout(() => {
      clearInterval(animateInterval);
    }, 2000);
  };

  const removeItem = (id) => {
    // const filteredArr = activeList.filter((item) => item.id !== id);
    const filteredArr = storedActiveList.filter((item) => item.id !== id);
    setStoredActiveList(filteredArr);
  };

  const removeFromStorage = (id) => {
    // remove from activeList
    removeItem(id);
    // remove from storedItems
    const filteredArr = storedItems.filter((item) => item.id !== id);
    setStoredItems(filteredArr);
  };

  const handleMarkComplete = (name) => {
    // STEP 1: Mark item as complete, remove it and update our Current List
    // a. Find the item with the provided name
    const currentItemIndex = storedActiveList.findIndex(
      (item) => item.name === name
    );
    // b. Mark the item as complete
    const updatedItem = {
      ...storedActiveList[currentItemIndex],
      isComplete: true,
    };
    // c. Update the item list with the updated item
    const newItems = [...storedActiveList];
    newItems[currentItemIndex] = updatedItem;
    setStoredActiveList(newItems);
    // STEP 2. Update the storedItems to reflect purchase order and frequency or new item
    // a. Check for name matches within the stored item list
    const existingItemIndex = storedItems.findIndex(
      (item) => item.name === name
    );
    // b. Mark the item's order of purchase
    // If new item, add it to our storedItems
    // Create, or increment, frequency and alter the order average
    const newOrder = count;
    setCount((current) => current + 1);
    // If no matching item exists in localStorage we'll add it
    if (existingItemIndex === -1) {
      console.log("avgCollectionOrder if: ", newOrder);
      const itemToStore = {
        name: name,
        freq: 1,
        avgCollectionOrder: newOrder,
        id: uuidv4(),
        key: uuidv4(),
      };
      setStoredItems([...storedItems, itemToStore]);
    } else {
      console.log("avgCollectionOrder else: ", newOrder);
      // If a matching item exists in localStorage, we update the stored data
      // Add 1 to Frequency
      const newFreq = storedItems[existingItemIndex].freq + 1;
      // Calaculate new Average Collection Order
      const newAvgCollectionOrder =
        Math.round(
          ((storedItems[existingItemIndex].avgCollectionOrder *
            storedItems[existingItemIndex].freq +
            newOrder) /
            (storedItems[existingItemIndex].freq + 1)) *
            1000
        ) / 1000;
      // Update existing item
      const existingItem = {
        ...storedItems[existingItemIndex],
        avgCollectionOrder: newAvgCollectionOrder,
        freq: newFreq,
      };
      // c. Update the item list with the updated item
      const newStoredItems = [...storedItems];
      newStoredItems[existingItemIndex] = existingItem;
      orderStoredItemsByFreq(newStoredItems);
      // setStoredItems(newStoredItems);
    }
  };

  const handleSlider = (e) => {
    // console.log("slider change");
    let sliderVal = Math.round(e.target.value);
    setListLength(sliderVal);
  };

  function handleStep(page) {
    // handleSortByOrder();
    setAppStep(page);
  }

  const [deleteHistory, setDeleteHistory] = useState(false);
  const toggleDeleteHistory = () => {
    setDeleteHistory(!deleteHistory);
  };

  const handleClearStore = () => {
    setStoredUserSettings({
      ...storedUserSettings,
      mockData: false,
    });
    // setStoredActiveList([]);
    setStoredItems([]);
    setStoredCosts([]);
  };

  const handleBinList = () => {
    setStoredActiveList([]);
  };

  // Toggle Mock Data
  const mockListData = require("./storedItems.json");
  const mockSpendData = require("./storedCosts.json");
  useEffect(() => {
    if (storedUserSettings.mockData === true) {
      setStoredItems(mockListData);
      setStoredCosts(mockSpendData);
    }
  }, []);
  const handleToggleMockData = () => {
    setStoredUserSettings({
      ...storedUserSettings,
      mockData: !storedUserSettings.mockData,
    });
    // reverse false and true here as the mockData key is not yet set
    if (storedUserSettings.mockData === false) {
      setStoredItems(mockListData);
      setStoredCosts(mockSpendData);
    } else {
      setStoredItems([]);
      setStoredCosts([]);
    }
  };

  // Toggle Spend Tracking
  const handleToggleSpend = () => {
    setStoredUserSettings({
      ...storedUserSettings,
      trackSpend: !storedUserSettings.trackSpend,
    });
  };

  const listDate = PrettyDate();
  // Share a List
  const [shareListTooltipVis, setShareListTooltipVis] = useState(false);
  const handleShareList = () => {
    const currentURL = window.location.origin + window.location.pathname;
    const encodedList = encodeURIComponent(JSON.stringify(shareableList));
    const shareURL = currentURL + "?q=" + encodedList;
    const shareTitle = "Blist, " + listDate;
    const shareData = {
      title: shareTitle,
      url: shareURL,
    };
    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      navigator.clipboard.writeText(shareURL).then(
        // Show / hide "Copied to clipboard" tooltip
        function () {
          setShareListTooltipVis(true);
          setTimeout(function () {
            setShareListTooltipVis(false);
          }, 1500);
        },
        function (err) {
          console.error(
            "Async: Could not copy text. Share link copied to clipboard. ",
            err
          );
        }
      );
    }
  };

  // show/ hide Quick List button
  const [quickListButtonVis, setQuickListButtonVis] = useState(true);

  // show / hide magic sort alert
  const [magicSortAlertVis, setMagicSortAlertVis] = useState(false);
  const handleMagicSort = () => {
    // console.log("storedActiveList", storedActiveList);
    const matchingItems = storedActiveList.filter((newItem) =>
      storedItems.some((existingItem) => existingItem.name === newItem.name)
    );

    // Only perform auto sort if there are items matching soredItems
    if (matchingItems.length !== 0) {
      const sortedMatchingItems = matchingItems.sort((a, b) => {
        const aFreq = storedItems.find(
          (item) => item.name === a.name
        ).avgCollectionOrder;
        const bFreq = storedItems.find(
          (item) => item.name === b.name
        ).avgCollectionOrder;
        return bFreq - aFreq;
      });
      const filteredStoredActiveList = storedActiveList.filter(
        (newItem) =>
          !storedItems.some(
            (existingItem) => existingItem.name === newItem.name
          )
      );

      const sortedList = [...filteredStoredActiveList, ...sortedMatchingItems];

      // THis will show if there are matching items, whether already sorted or not
      // Not sure how to get this to only show when sorting occurs
      if (matchingItems.length >= 2) {
        setMagicSortAlertVis(true);
        setTimeout(function () {
          setMagicSortAlertVis(false);
        }, 1000); // duration visible
      }
      setStoredActiveList(sortedList);
    }
  };

  // A simplified list for sharing
  function sanitisedActiveList(arr) {
    const sanitisedArr = arr.map(({ name, ...rest }) => {
      return name;
    });
    return sanitisedArr;
  }

  // Share Blist App (Footer link)
  const [shareAppTooltipVis, setShareAppTooltipVis] = useState(false);
  const handleShareApp = () => {
    const shareURL = window.location.origin + window.location.pathname;
    const shareTitle = "Blist — pretty good lists";
    const shareData = {
      title: shareTitle,
      url: shareURL,
    };
    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      navigator.clipboard.writeText(shareURL).then(
        // Show / hide "Copied to clipboard" tooltip
        function () {
          setShareAppTooltipVis(true);
          setTimeout(function () {
            setShareAppTooltipVis(false);
          }, 1500);
        },
        function (err) {
          console.error(
            "Async: Could not copy text. Share link copied to clipboard. ",
            err
          );
        }
      );
    }
  };

  // Bin List Modal
  const [isOpenBinList, setIsOpenBinList] = useState(false);
  function closeModalBinList() {
    setIsOpenBinList(false);
  }
  function openModalBinList(itemId, nameToDelete) {
    setIsOpenBinList(true);
  }

  // Delete item Modal
  const [isOpenDeepRemove, setIsOpenDeepRemove] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [nameToDelete, setNameToDelete] = useState("");
  function closeModalDeepRemove() {
    setIsOpenDeepRemove(false);
    // Reset these
    setIdToDelete();
    setNameToDelete("");
  }
  function openModalDeepRemove(itemId, nameToDelete) {
    setIsOpenDeepRemove(true);
    // We need to pass these states to the Modal, then route them through App to the Item component
    setIdToDelete(itemId);
    setNameToDelete(nameToDelete);
  }

  // Cost Item Modal
  const [isOpenCost, setIsOpenCost] = useState(false);
  function closeModalCost() {
    setIsOpenCost(false);
    // reset our purchase order count (also used for number of products purchased)
    // delay, so the count doesn't update in the modal heading
    setTimeout(() => {
      setCount(1);
      console.log("count reset in closeModalCost()");
    }, 500);
  }
  function openModalCost() {
    setIsOpenCost(true);
  }

  // Slider Modal
  const [isOpenSlider, setIsOpenSlider] = useState(false);
  function closeModalSlider() {
    // setCount(1); // @Reg – Count wasn't being reset
    setIsOpenSlider(false);
  }
  function openModalSlider() {
    // if (storedUserSettings.trackPrepopulate) {
    // Ensure our starting value for the slider is set correctly
    setListLength(Math.round(storedItemsListLength / 2));
    setIsOpenSlider(true);
    // }
  }

  // Info Modal
  const [isOpenHelp, setIsOpenHelp] = useState(false);
  function closeModalHelp() {
    setIsOpenHelp(false);
    setStoredUserSettings({
      ...storedUserSettings,
      firstVisit: false,
    });
  }
  function openModalHelp() {
    setIsOpenHelp(true);
  }

  // Open Cost Modal (if list completed)
  function checkFinish() {
    if (storedActiveListLength === 1) {
      // count of 1 means zero items
      if (storedUserSettings.trackSpend === true && count !== 1) {
        openModalCost();
      }
      setCount(1); // @Reg – Count wasn't being reset
    }
  }

  const [cost, setCost] = useState(0);

  function resetUrl() {
    // Ensure ?q= string is removed when leaving list page
    // replaceState(data,title,url);
    window.history.replaceState(
      {},
      "Blist",
      window.location.origin + window.location.pathname
    );
  }

  function handleAddCost() {
    if (cost !== 0) {
      let quantity = count - 1;
      let date = new Date();
      // add leading zero if necessary
      const todaysYear = String("0" + date.getFullYear()).slice(-2);
      const todaysMonth = String("0" + (date.getMonth() + 1)).slice(-2);
      const todaysDay = String("0" + date.getDate()).slice(-2);
      // returns "230131"
      const todaysDate = todaysYear + todaysMonth + todaysDay;
      // We want to add all purchase costs and quantity for same date together
      const existingItemIndex = storedCosts.findIndex(
        (item) => item.date === todaysDate
      );
      // if not a duplicae
      if (existingItemIndex === -1) {
        let newArr = storedCosts;
        const newSpend = {
          date: todaysDate,
          year: todaysYear,
          month: todaysMonth,
          day: todaysDay,
          cost: cost,
          itemsPurchased: quantity,
        };
        setStoredCosts([...newArr, newSpend]);
      } else {
        let newArr = storedCosts;
        const sumOfCosts = newArr[existingItemIndex].cost + cost;
        const sumOfItemsPurchased =
          newArr[existingItemIndex].itemsPurchased + quantity;
        const newSpendObj = {
          date: todaysDate,
          year: todaysYear,
          month: todaysMonth,
          day: todaysDay,
          cost: sumOfCosts,
          itemsPurchased: sumOfItemsPurchased,
        };
        newArr.splice(existingItemIndex, 1);
        setStoredCosts([...newArr, newSpendObj]);
      }
      setCost(0); // reset cost var
      setAppStep("spending");
    }
  }

  return (
    <>
      {appStep === "list" && (
        <>
          <div
            style={{
              opacity: showSplash ? "1" : "0",
              pointerEvents: showSplash ? "auto" : "none",
              transition: "opacity ease 0.4s",
            }}
            className="absolute z-30 w-full h-full flex flex-col"
          >
            <Splashscreen
              openModalHelp={openModalHelp}
              firstVisit={storedUserSettings.firstVisit}
              setShowSplash={setShowSplash}
            />
          </div>
          <div
            id="list-wrapper"
            className="pt-[3.5rem] pb-[5.5rem] min-h-min h-full flex flex-col grow overflow-y-auto overscroll-none"
            // style={{ transform: "translateZ(0)" }} // Attempt (@Reg – does this work?) to fix iOS scroll freeze bug: https://stackoverflow.com/questions/39692337/div-scrolling-freezes-sometimes-if-i-use-webkit-overflow-scrolling
          >
            {storedActiveListLength === 0 &&
              storedItemsListLength !== 0 &&
              !magicSortAlertVis && (
                <button
                  className="btn btn-secondary w-1/2 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
                  onClick={openModalSlider}
                  style={{
                    opacity: quickListButtonVis ? "1" : "0",
                    transition: "all 0.2s",
                    visibility: quickListButtonVis ? "visible" : "hidden",
                  }}
                >
                  <span className="-mt-1 mr-2">
                    <RiPencilFill className="w-4 h-4" />
                  </span>
                  Quick list
                </button>
              )}

            <Alert
              style={{
                opacity: magicSortAlertVis ? "1" : "0",
                transition: "all 0.15s",
                visibility: magicSortAlertVis ? "visible" : "hidden",
              }}
              classNames={magicSortAlertVis && "visible"}
            >
              <BsStars className="alert-sorted-stars top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2" />
              <span className="alert-sorted-text z-10 relative">Sorted</span>
            </Alert>

            <Header
              appStep={appStep}
              openModalBinList={openModalBinList}
              handleStep={handleStep}
              handleShareList={handleShareList}
              shareListTooltipVis={shareListTooltipVis}
              setAppStep={setAppStep}
              isStoredActiveListEmpty={isStoredActiveListEmpty}
            />
            {/* {count} */}
            <div className="item-list grow flex flex-col justify-end bg-[#e9f8fb]">
              {storedActiveList &&
                storedActiveList.map((item) => {
                  return (
                    <Item
                      key={item.id}
                      id={item.id}
                      name={item.name}
                      removeItem={removeItem}
                      isComplete={item.isComplete}
                      handleMarkComplete={handleMarkComplete}
                      openModal={openModalDeepRemove}
                      handleScrollList={handleScrollList}
                      checkFinish={checkFinish}
                    />
                  );
                })}
              <div id="list-bottom" />
            </div>
            <div className="text-input-wrapper">
              <TextInput
                storedActiveList={storedActiveList}
                handleInputChange={handleInputChange}
                addButtonState={addButtonState}
                setAddButtonState={setAddButtonState}
                addItem={addItem}
                inputText={inputText}
                handleMagicSort={handleMagicSort}
                setQuickListButtonVis={setQuickListButtonVis}
              />
            </div>
          </div>
          <ModalSlider
            isOpenSlider={isOpenSlider}
            closeModalSlider={closeModalSlider}
            listLength={listLength}
            storedItemsListLength={storedItemsListLength}
            // storedAvgCost={storedAvgCost}
            handleSlider={handleSlider}
            handleSortByOrder={handleSortByOrder}
          />
        </>
      )}
      {appStep === "spending" && (
        <div className="pt-[3.5rem] min-h-min h-full flex flex-col grow overflow-y-auto overscroll-none">
          <Header
            appStep={appStep}
            handleStep={handleStep}
            isStoredActiveListEmpty={isStoredActiveListEmpty}
            setAppStep={setAppStep}
          />
          <div className="haas-55 px-5 overflow-y-auto block pb-20 grow bg-[#fdfefb]">
            <h2 className="mt-9 lede">Spending</h2>
            <div className="icon-btn-group space-y-6">
              {storedCosts.length !== 0 ? (
                <Chart
                  storedCosts={storedCosts}
                  storedItems={storedItems}
                  setAppStep={setAppStep}
                />
              ) : (
                <>
                  <div className="hr mt-3 mb-9"></div>
                  <p>You have no spending history.</p>
                </>
              )}
              <button className="flex items-center" onClick={handleToggleSpend}>
                <div
                  className={`${
                    storedUserSettings.trackSpend
                      ? "bg-[#039cff]"
                      : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full transition-colors`}
                >
                  <span
                    className={`${
                      storedUserSettings.trackSpend
                        ? "translate-x-6"
                        : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                  />
                </div>
                <b className="ml-3">Track spending</b>
              </button>
              {showMockData && (
                <button
                  className="flex items-center"
                  onClick={handleToggleMockData}
                >
                  <div
                    className={`${
                      storedUserSettings.mockData
                        ? "bg-[#039cff]"
                        : "bg-gray-200"
                    } relative inline-flex h-6 w-11 items-center rounded-full transition-colors`}
                  >
                    <span
                      className={`${
                        storedUserSettings.mockData
                          ? "translate-x-6"
                          : "translate-x-1"
                      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                    />
                  </div>
                  <b className="ml-3">Mock data</b>
                </button>
              )}
              {(storedCosts.length !== 0 || storedItems.length !== 0) && (
                <>
                  <button
                    className={`icon-btn ${deleteHistory ? "hidden" : "flex"}`}
                    onClick={() => {
                      toggleDeleteHistory();
                      setTimeout(() => {
                        setDeleteHistory(false);
                      }, 5000);
                    }}
                  >
                    <span className="ml-0 mr-2">
                      <RiDeleteBinLine className="w-6 h-6 shrink-0" />
                    </span>
                    <span className="text-left">Delete shopping history</span>
                  </button>

                  <button
                    className={`icon-btn icon-btn-caution ${
                      deleteHistory ? "flex" : "hidden"
                    }`}
                    onClick={() => {
                      handleClearStore();
                      toggleDeleteHistory();
                    }}
                  >
                    <span className="ml-0 mr-2">
                      <RiDeleteBinLine className="w-6 h-6 shrink-0" />
                    </span>
                    <span className="text-left">
                      Click to confirm clearing local cache
                    </span>
                  </button>
                </>
              )}
            </div>
          </div>
          <Footer
            handleShareApp={handleShareApp}
            shareAppTooltipVis={shareAppTooltipVis}
          />
        </div>
      )}
      {appStep === "help" && (
        <div className="pt-[3.5rem] min-h-min h-full flex flex-col grow overflow-y-auto overscroll-none">
          <Header
            appStep={appStep}
            handleStep={handleStep}
            isStoredActiveListEmpty={isStoredActiveListEmpty}
            setAppStep={setAppStep}
          />
          <div className="haas-55 overflow-y-auto block pb-20 px-5 grow bg-[#fdfefb]">
            <Info />
          </div>
          <Footer
            handleShareApp={handleShareApp}
            shareAppTooltipVis={shareAppTooltipVis}
          />
        </div>
      )}
      <ModalBinList
        isOpenBinList={isOpenBinList}
        setIsOpenBinList={setIsOpenBinList}
        closeModalBinList={closeModalBinList}
        handleBinList={handleBinList}
      />
      <ModalDeepRemove
        isOpenDeepRemove={isOpenDeepRemove}
        setIsOpenDeepRemove={setIsOpenDeepRemove}
        closeModalDeepRemove={closeModalDeepRemove}
        idToDelete={idToDelete}
        nameToDelete={nameToDelete}
        removeFromStorage={removeFromStorage}
        checkFinish={checkFinish}
      />
      <ModalCost
        isOpenCost={isOpenCost}
        setIsOpenCost={setIsOpenCost}
        closeModalCost={closeModalCost}
        handleAddCost={handleAddCost}
        count={count}
        setCost={setCost}
        setAppStep={setAppStep}
      />
      <ModalHelp
        isOpenHelp={isOpenHelp}
        setIsOpenHelp={setIsOpenHelp}
        closeModalHelp={closeModalHelp}
        firstVisit={storedUserSettings.firstVisit}
      />
    </>
  );
}

export default App;
