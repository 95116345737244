import React, { useState } from "react";
import Emoji from "./Emoji";
import { RiArrowUpCircleFill } from "react-icons/ri";
import { useRef } from "react";

const TextInput = (props) => {
  // State to store the timeout ID
  const [timeoutId, setTimeoutId] = useState(null);

  const [placeholder, setPlaceholder] = useState("Add item…");
  const handleOnFocus = () => {
    // hide the Quick List button
    props.setQuickListButtonVis(false);
    props.storedActiveList.length === 0
      ? setPlaceholder("Add item…")
      : setPlaceholder("");
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };
  const handleOnBlur = () => {
    setPlaceholder("Add item…");
    props.setQuickListButtonVis(true);
    const id = setTimeout(() => {
      props.handleMagicSort();
    }, 500); // delay before showing "Sorted alert" (if not interupted by onFocus)
    setTimeoutId(id);
  };
  const ref = useRef(null);

  const handleAddClick = () => {
    ref.current.focus();
    props.setAddButtonState("fill-[#e9f8fb]");
  };
  return (
    <div className="pt-3.5 pb-[1.125rem] border-gray-200  border-t">
      <div className="mx-2 relative rounded-full shadow-[inset_0_3px_6px_-3px_rgba(0,0,0,0.5)] flex align-middle justify-center px-2 py-1 bg-[#e9f8fb]">
        <input
          // autoComplete="off" // Manu and Davo want it on
          ref={ref}
          spellCheck="false"
          // autoFocus
          maxLength="50"
          className="pr-10 pl-10 outline-none whitespace-nowrap caret-slate-900 text-slate-900 bg-transparent tracking-widest uppercase w-full h-12 border-0 border-none"
          placeholder={placeholder}
          onChange={props.handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              props.addItem();
              setPlaceholder("");
              props.setAddButtonState("fill-[#e9f8fb]");
            }
          }}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          value={props.inputText}
        />
        <span className="absolute left-4 top-4 -mt-[0.125rem]">
          <Emoji input={true} label={props.inputText} />
        </span>
        <button
          className="absolute right-3 top-3 bg-[#e9f8fb]"
          onClick={() => {
            props.addItem();
            handleAddClick();
          }}
        >
          <RiArrowUpCircleFill className={`${props.addButtonState} w-8 h-8`} />
        </button>
      </div>
    </div>
  );
};

export default TextInput;
