// import React, { useEffect, useRef, useState } from "react";
// import Footer from "./Footer";
import iconBrain from "./images/icon-white-brain.svg";
import iconTrolley from "./images/icon-white-trolley.svg";
import iconShare from "./images/icon-white-share.svg";
import iconSpend from "./images/icon-white-spend.svg";

const Info = (props) => {
  return (
    <>
      <h2 className="mt-9 lede">
        {/* Shop smart */}
        {/* Smart shopping assistant */}
        {/* About Blist */}
        {/* Blist Features */}
        {/* Shop smarter with Blist */}
        Enhanced shopping
        {/* Elegant simplicity */}
        {/* Shop smarter.
            <br />
            Write and share
            <br />
            lists with ease. */}
        {/* <span className="block haas-65 text-lg">Smart shopping assistant</span> */}
      </h2>
      <div className="hr mt-3 mb-10"></div>
      {/* becomes increasingly helpful with use. This is because Blist
        analyses your item purchasing frequencies and the order of collection
        and uses this history to help generate quick lists and to sort your
        items for optimal collection ease. */}
      {/* <br />
      <p>
        Blist increasingly enhances your list creation and shopping experience
        as your history grows, because Blist analyses item frequency and the
        order in which items are checked off to generate sorting algorithms to
        ease both list creation and item collection.
      </p> */}
      <div className="mt-6 flex flex-col grow">
        {/* <div className="lede">
            <p>
              Write and share
              <br />
              lists with ease.
            </p>
            <p>
              Shop efficiently
              <br />
              with a smile.
            </p>
          </div> */}
        {/* Fun and efficient supermarket experience, from writing and sharing
          lists to shopping. */}
        {/* Blist makes your supermarket experience, from writing and sharing
            lists to shopping, fun and&nbsp;efficient. */}
        {/* Blist makes it quick and easy to create and share lists. Intuitive
            item ordering helps you shop faster and more efficiently. Put the{" "}
            <i>super</i> in your supermarket shopping today. */}
        {/* And you can view your spending with beautiful charts. */}
        <div className="feature-list grid gap-5">
          {/* <div className="feature-list grid grid-cols-2 gap-5"> */}
          <div className="feature-list-item">
            <h2>
              <img src={iconShare} className="w-14 h-14" alt="Share" />
              <span>Share Lists</span>
            </h2>
            <p className="mt-3">
              Sharing is fast and easy—even with those who have never used
              Blist.
            </p>
          </div>
          <div className="feature-list-item">
            <h2>
              <img src={iconTrolley} className="w-14 h-14" alt="Trolley" />
              <span>Quick Lists</span>
              {/* <span>Create lists dynamically</span> */}
            </h2>
            <p className="mt-3">
              {/* Spin up dynamic lists of most commonly purchased items. */}
              Kick-start lists with commonly purchased items.
            </p>
          </div>
          <div className="feature-list-item">
            <h2>
              <img src={iconBrain} className="w-14 h-14" alt="Brain" />
              <span>Route Optimisation</span>
            </h2>
            <p className="mt-3">
              {/* Blist smart-sorts your lists, putting the next collection items
              nearest to your thumb. */}
              Items are algorithmically sorted to help speed up shopping.*
              {/* Smart sorting puts shop items closest to your thumb
              based on your historical item collection orders. arranged in the
              smartest order according to your personal shopping route */}
            </p>
          </div>
          <div className="feature-list-item">
            <h2>
              <img src={iconSpend} className="w-14 h-14" alt="Chart" />
              <span>Track Spending</span>
            </h2>
            <p className="mt-3">
              On a budget? Record the cost at checkout to chart your spending.*
            </p>
          </div>
          <p className="text-xs mt-3 relative text-slate-600 leading-[1.125rem]">
            <span className="absolute">*</span>
            <span className="pl-2.5 block italic">
              We respect your privacy. Your data is solely yours. Items and
              costs are held in your browser and can be deleted at any time in
              your Spending settings.
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Info;
