import React, { useEffect, useRef, useState } from "react";
import logoBg from "./images/blist-logo-white-bg.png";
import logo from "./images/blist-logo-white.png";

const Splashscreen = (props) => {
  // Check images loaded to kick off logo seedling animation
  const logoImgBg = useRef(null);
  const logoImg = useRef(null);
  const [logoLoaded, setLogoLoaded] = useState(false);
  useEffect(() => {
    setLogoLoaded(true);
    setTimeout(() => {
      props.setShowSplash(false);
      if (props.firstVisit) {
        props.openModalHelp();
      }
    }, 1250);
  }, [logoImgBg, logoImg]);

  return (
    <div
      id="splashscreen"
      className="flex items-center justify-center flex-col min-h-full px-5"
      style={{
        backgroundImage:
          "linear-gradient(135deg, rgba(255,8,0,1) 0%,rgba(255,57,0,1) 35%,rgba(226,0,112,1) 100%)",
      }}
    >
      <div className="logo-wrapper relative aspect-square w-1/2 -mt-16">
        <div
          className={`${
            logoLoaded && "logo-loaded"
          } logo-bg absolute top-0 overflow-hidden`}
        >
          <img
            ref={logoImgBg}
            src={logoBg}
            className={`${logoLoaded ? "block" : "hidden"} `}
            alt="logo background"
          />
        </div>
        <img
          ref={logoImg}
          src={logo}
          className={`${logoLoaded ? "block" : "hidden"}`}
          alt="logo"
        />
      </div>
      <div className="absolute haas-55 text-xs bottom-[20px] left-[18px]">
        <span className="text-[#fcc7cf]">2023 © JB and Reg</span>
      </div>
    </div>
  );
};

export default Splashscreen;
