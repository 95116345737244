import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function ModalCost(props) {
  let costInputRef = useRef(null); // set element:focus
  const handleInputChange = (e) => {
    let inputVal = Number(e.target.value);
    props.setCost(inputVal || 0);
  };

  return (
    <>
      <Transition appear show={props.isOpenCost} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={props.closeModalCost}
          initialFocus={costInputRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 modal-bg" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="modal w-full max-w-md transform overflow-hidden bg-[#fdfefb] text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h2" className="modal-heading">
                    Cost of {props.count - 1} item
                    {props.count === 2 ? "" : "s"}
                  </Dialog.Title>
                  <div className="normal-case">
                    <div className="input-cost mt-3 text-lg shadow-[inset_0_3px_6px_-3px_rgba(0,0,0,0.5)]">
                      <input
                        ref={costInputRef}
                        autoComplete="off"
                        spellCheck="false"
                        autoFocus
                        maxLength="4"
                        className="outline-none haas-65 caret-slate-900 text-slate-900 bg-transparent tracking-widest uppercase w-full h-12 border-0 border-none"
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            props.handleAddCost();
                            props.closeModalCost();
                          } else if (
                            e.key === "Backspace" ||
                            e.key === "Delete"
                          ) {
                            return;
                          } else if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={props.inputText}
                        type="tel"
                      />
                    </div>
                    {/* <p className="text-xs mt-3 relative text-slate-600 leading-[1.125rem]">
                      Note: Spend tracking can be turned off in Charts.
                    </p> */}
                  </div>
                  <div className="mt-8 flex space-x-4">
                    <button
                      className="btn btn-secondary w-1/2"
                      onClick={() => {
                        props.setCost(0);
                        props.closeModalCost();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn w-1/2"
                      onClick={() => {
                        props.handleAddCost();
                        props.closeModalCost();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
