import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import Tooltip from "./Tooltip";
import {
  RiShareForwardFill,
  RiPencilFill,
  RiBarChart2Fill,
  RiDeleteBin6Line,
} from "react-icons/ri";
import { BsFillInfoCircleFill } from "react-icons/bs";

const Header = (props) => {
  const [isShowing, setIsShowing] = useState(false);
  // Show / hide list's Share button
  useEffect(() => {
    setIsShowing(props.appStep === "list" && !props.isStoredActiveListEmpty);
  }, [props.appStep, props.isStoredActiveListEmpty]);

  return (
    <div
      id="header"
      className=""
      style={{
        background:
          "linear-gradient(135deg, rgba(255,8,0,1) 0%,rgba(255,57,0,1) 35%,rgba(226,0,112,1) 100%)",
      }}
    >
      <div className="flex items-center absolute space-x-2 left-[0.75rem]">
        <div className={`${isShowing && "header-sub-is-open"} flex rounded`}>
          <button
            className={`${props.appStep === "list" ? "is-active" : ""} ${
              isShowing && " has-sub-right"
            } header-button relative z-10`}
            onClick={() => {
              props.handleStep("list");
            }}
          >
            <RiPencilFill />
          </button>
          <Transition
            className={`${
              isShowing && "is-visible"
            } header-button-sub rounded-br rounded-tr`}
            show={isShowing}
            enter="transition-width ease-out duration-500 overflow-hidden"
            enterFrom="w-0"
            enterTo="w-[4.8125rem]"
            leave="transition-width ease-in duration-500"
            leaveFrom="w-[4.8125rem]"
            leaveTo="w-0"
          >
            <div className="relative">
              <button
                className="header-button"
                onClick={props.openModalBinList}
              >
                <RiDeleteBin6Line />
              </button>
            </div>
            <div className="sub-header-divider"></div>
            <div className="relative">
              <button className="header-button" onClick={props.handleShareList}>
                {/* <RiShareBoxFill /> */}
                <RiShareForwardFill />
              </button>
              <Tooltip
                classNames="tooltip tooltip-below left-[1.125rem]"
                style={{
                  opacity: props.shareListTooltipVis ? "1" : "0",
                  transition: "all .15s",
                  visibility: props.shareListTooltipVis ? "visible" : "hidden",
                }}
              >
                Share&nbsp;link
                <br />
                copied
              </Tooltip>
            </div>
          </Transition>
        </div>
      </div>
      <div className="flex items-center absolute pr-[8px] space-x-3 right-[0.25rem]">
        <button
          className={`${
            props.appStep === "spending" ? "is-active" : ""
          } header-button`}
          onClick={() => {
            props.setAppStep("spending");
          }}
        >
          <RiBarChart2Fill />
        </button>
        <button
          className={`${
            props.appStep === "help" ? "is-active" : ""
          } header-button`}
          onClick={() => {
            props.setAppStep("help");
          }}
        >
          <BsFillInfoCircleFill />
        </button>
      </div>
    </div>
  );
};

export default Header;
