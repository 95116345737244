import React, { useState, useRef } from "react";
import Emoji from "./Emoji";
import { Transition } from "@headlessui/react";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";

const Item = (props) => {
  // Touch events: scroll / swipe left / long press
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchStartY, setTouchStartY] = useState(null);
  const touchTimeout = useRef(null);
  const minXSwipeDistance = 80;
  const minYSwipeDistance = 30;
  const longPressDuration = 350;
  const [isScrollingList, setIsScrollingList] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
    setTouchStartY(e.touches[0].clientY);
    touchTimeout.current = setTimeout(() => {
      if (!isScrollingList) {
        handleLongPress();
      }
    }, longPressDuration);
  };

  const handleTouchMove = (e) => {
    const touchDeltaX = touchStartX - e.touches[0].clientX;
    const touchDeltaY = touchStartY - e.touches[0].clientY;
    if (
      Math.abs(touchDeltaY) > minYSwipeDistance &&
      Math.abs(touchDeltaY) > Math.abs(touchDeltaX)
    ) {
      setIsScrollingList(true);
      clearTimeout(touchTimeout.current);
    } else if (Math.abs(touchDeltaX) > minXSwipeDistance) {
      setSwipeDirection(touchDeltaX > 0 ? "left" : "right");
      clearTimeout(touchTimeout.current);
      setIsScrollingList(true);
    }
  };
  const handleTouchEnd = () => {
    if (swipeDirection === "left") {
      swipeLeftRemoveItem();
    }
    setSwipeDirection(null);
    setIsScrollingList(false);
    clearTimeout(touchTimeout.current);
  };
  const swipeLeftRemoveItem = () => {
    setIsShowing(false);
    setItemRemoval(true);
  };
  const handleLongPress = () => {
    props.openModal(props.id, props.name);
  };

  // To style closing 'X'
  const [itemRemoval, setItemRemoval] = useState(false);

  // Transition
  const [isShowing, setIsShowing] = useState(true);

  return (
    <Transition
      appear={true}
      beforeEnter={props.handleScrollList}
      afterLeave={() => {
        props.removeItem(props.id);
        props.checkFinish();
      }}
      show={isShowing}
      className="item"
    >
      <Transition.Child
        enter="transition-all ease-in-cubic duration-[600ms]"
        enterFrom="opacity-0 max-h-0"
        enterTo="opacity-100 max-h-24"
        leave="transition-all ease-in-cubic duration-[600ms]"
        leaveFrom={`opacity-100 max-h-24 ${
          props.isComplete ? "bg-green-500" : "left-0 bg-red-700"
        }`}
        leaveTo={`opacity-0 max-h-0 ${
          props.isComplete ? "bg-green-500" : "-left-full bg-red-700"
        }`}
        className="relative overflow-hidden"
      >
        <div
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          className="tracking-widest select-none leading-none py-[0.6875rem] px-6 flex items-center justify-between"
          component={"div"}
          // stopPropagation={false}
        >
          <div className="flex items-center whitespace-nowrap overflow-hidden text-ellipsis w-full">
            <Emoji className="absolute" label={props.name} />
            <span className="ml-8 w-[calc(100%-4rem)] overflow-hidden text-ellipsis">
              {props.name}
            </span>
          </div>
          <div className="flex flex-row space-x-4 h-8 relative">
            <button
              className="hidden absolute right-10 sm:block py-[0.625rem] px-3 -top-2.5"
              onClick={() => {
                setIsShowing((isShowing) => false);
              }}
            >
              <RiCloseCircleFill className="w-8 h-8 fill-red-700" />
            </button>
            <button
              className="py-[0.625rem] px-3 absolute -right-[1rem] -top-2.5"
              onClick={() => {
                setIsShowing((isShowing) => false);
                props.handleMarkComplete(props.name);
              }}
            >
              {itemRemoval && (
                <RiCloseCircleFill className="w-8 h-8 fill-red-700" />
              )}
              {props.isComplete ? (
                <RiCheckboxCircleFill className="w-8 h-8 fill-green-700" />
              ) : (
                <RiCheckboxCircleFill className="w-8 h-8 fill-slate-200" />
              )}
            </button>
          </div>
        </div>
      </Transition.Child>
    </Transition>
  );
};

export default Item;
