import React, { useEffect, useState } from "react";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";

const Chart = (props) => {
  const data = props.storedCosts;
  const [current, setCurrent] = useState("month");
  // console.log("data", data);
  const [currentYear, setCurrentYear] = useState(
    Number(data[data.length - 1].year)
  );
  const [currentMonth, setCurrentMonth] = useState(
    Number(data[data.length - 1].month)
  );
  // default viewPeriod to the month of the most recent shop
  const [viewPeriod, setViewPeriod] = useState(
    data.filter(
      (each) =>
        Number(each.year) === currentYear && Number(each.month) === currentMonth
    )
  );
  const [monthlyCostData, setMonthlyCostData] = useState([]); // [{cost: 3175, monthOfYear: 1, currentYear: 23,}, {cost: 1040, monthOfYear: 2, currentYear: 24,}, ...]

  // Swipe
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);
  const [touchStartY, setTouchStartY] = useState(null);
  const [touchEndY, setTouchEndY] = useState(null);
  // the required x distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 80;
  const onTouchStart = (e) => {
    setTouchEndX(null); // otherwise the swipe is fired even with usual touch events
    setTouchEndY(null);
    setTouchStartX(e.targetTouches[0].clientX);
    setTouchStartY(e.targetTouches[0].clientY);
  };
  const onTouchMove = (e) => {
    setTouchEndX(e.targetTouches[0].clientX);
    setTouchEndY(e.targetTouches[0].clientY);
  };
  const onTouchEnd = () => {
    if (!touchStartX || !touchEndX || !touchStartY || !touchEndY) return;
    const distanceX = Math.abs(touchStartX - touchEndX);
    const distanceY = Math.abs(touchStartY - touchEndY);
    const isLeftSwipe =
      touchStartX > touchEndX &&
      distanceX > minSwipeDistance &&
      distanceX > distanceY;
    const isRightSwipe =
      touchStartX < touchEndX &&
      distanceX > minSwipeDistance &&
      distanceX > distanceY;
    // Disable swipe
    if (current === "all") {
      return;
    }
    // Otherwise allow swipes within available date ranges
    if (isLeftSwipe) {
      if (
        (current === "month" &&
          currentMonth >= lastMonth &&
          currentYear >= lastYear) ||
        (current === "year" && currentYear >= lastYear)
      ) {
        return;
      }
      handleNext();
    }
    if (isRightSwipe) {
      if (
        (current === "month" &&
          currentMonth <= firstMonth &&
          currentYear <= firstYear) ||
        (current === "year" && currentYear <= firstYear)
      ) {
        return;
      }
      handlePrev();
    }
  };

  useEffect(() => {
    let viewResults;
    // Generate arr of data by year
    if (current === "month" || current === "year") {
      viewResults = data.filter(
        (each) =>
          Number(each.year) === currentYear &&
          Number(each.month) === currentMonth
      );
      // Generate arr of summed monthly costs data
      if (current === "year") {
        const yearArr = data.filter(
          (each) => Number(each.year) === currentYear
        );
        let newCostArr = []; // [{01, 1600}, {02, 1850}, ...]
        for (let i = 1; i <= 12; i++) {
          let byMonthArr = yearArr.filter((each) => Number(each.month) === i);
          if (byMonthArr.length !== 0) {
            const costSum = byMonthArr.reduce((accumulator, object) => {
              return accumulator + object.cost;
            }, 0);
            const itemsSum = byMonthArr.reduce((accumulator, object) => {
              return accumulator + object.itemsPurchased;
            }, 0);
            let newObj = {
              cost: costSum,
              monthOfYear: i,
              year: currentYear,
              itemsPurchased: itemsSum,
            };
            newCostArr = [...newCostArr, newObj];
          }
        }
        setMonthlyCostData(newCostArr);
      }
    } else {
      // "all"
      return (viewResults = () => data); // or viewResults = data;
      // viewResults = data;
    }
    setViewPeriod(viewResults);
  }, [currentYear, currentMonth, current, data]);

  // Date vars
  let daysInFeb = currentYear % 4 === 0 ? 29 : 28;
  const numOfDays = [31, daysInFeb, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const shortMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Chart nav
  const handlePrev = () => {
    if (current === "month") {
      if (currentMonth === 1) {
        setCurrentMonth(12);
        setCurrentYear((curr) => curr - 1);
      } else {
        setCurrentMonth((curr) => curr - 1);
      }
    } else if (current === "year") {
      setCurrentYear((curr) => curr - 1);
    } else {
      return;
    }
  };
  const handleNext = () => {
    if (current === "month") {
      if (currentMonth === 12) {
        setCurrentMonth(1);
        setCurrentYear((curr) => curr + 1);
      } else {
        setCurrentMonth((curr) => curr + 1);
      }
    } else if (current === "year") {
      setCurrentYear((curr) => curr + 1);
    } else {
      return;
    }
  };

  // First & Last dates
  const firstMonth = Number(data[0].month);
  const firstYear = Number(data[0].year);
  const lastMonth = Number(data[data.length - 1].month);
  const lastYear = Number(data[data.length - 1].year);

  // Chart title
  let heading;
  if (current === "month") {
    heading = monthNames[currentMonth - 1] + ", 20" + currentYear;
  } else if (current === "year") {
    heading = "20" + currentYear;
  } else {
    heading =
      currentYear === firstYear
        ? shortMonthNames[firstMonth - 1] + ", 20" + currentYear + " – Today"
        : shortMonthNames[firstMonth - 1] + ", 20" + firstYear + " – Today";
  }

  // Render chart
  useEffect(() => {
    // Get the DPR and size of the canvas
    const dpr = window.devicePixelRatio;
    const canvas = document.getElementById("canvas");
    const canvasWidth = document.getElementById("canvas-wrapper").clientWidth;
    const canvasHeight = canvasWidth;
    const rect = canvas.getBoundingClientRect();
    // Set the "actual" size of the canvas
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;
    // Scale the context to ensure correct drawing operations
    const cx = canvas.getContext("2d");
    cx.scale(dpr, dpr);
    // Set the "drawn" size of the canvas
    canvas.style.width = `${rect.width}px`;
    canvas.style.height = `${rect.height}px`;
    // Clear <canvas />
    cx.clearRect(0, 0, canvasWidth, canvasHeight);
    cx.fillStyle = "#e9f8fb";
    cx.fillRect(0, 0, canvasWidth, canvasHeight);
    // General canvas vars
    const centerX = canvasWidth / 2;
    const canvasPadding = 32;
    const chartWidth = canvasWidth - canvasPadding; // canvas padding
    const chartHeight = canvasHeight / 2;
    let startY = canvasHeight - 52; // visually pleasant
    // Bar dimensions
    let barWidth, barGap, duration, highestValue;
    if (current === "month") {
      barGap = 1;
      duration = numOfDays[currentMonth - 1];
      barWidth = chartWidth / duration - barGap;
      highestValue = Math.max(...data.map((o) => o.cost));
    } else if (current === "year") {
      barGap = 1;
      duration = 12;
      barWidth = chartWidth / duration - barGap;
      // highestValue = monthlyHeighestValue;
      highestValue = Math.max(...monthlyCostData.map((o) => o.cost));
    } else {
      // current === "all"
      barGap = 0.5;
      duration = data.length;
      barWidth = chartWidth / duration - barGap;
      if (barWidth <= barGap) {
        barGap = 0;
        barWidth = chartWidth / duration;
      }
      // highestValue = dailyHeighestValue;
      highestValue = Math.max(...data.map((o) => o.cost));
    }

    // Height calcs for y-axis labels and bar heights
    let topStep = Math.ceil(highestValue / 100) * 100;
    let tallestBar = (highestValue / topStep) * chartHeight;

    // Draw chart
    for (let i = 0; i < duration; i += 1) {
      let startX = i * barWidth + i * barGap + canvasPadding / 2;
      // x-axis labels (dates)
      cx.fillStyle = "#0f172a";
      cx.font = "10px NHaasGroteskTXPro-65Md";
      if (current !== "all") {
        if (current === "month" ? i % 2 === 0 : i % 1 === 0) {
          let textWidth = cx.measureText(
            current === "month" ? i + 1 : shortMonthNames[i]
          ).width;
          cx.fillText(
            current === "month" ? i + 1 : shortMonthNames[i],
            startX + barWidth / 2 - textWidth / 2,
            startY + 14
          );
        }
      }
      // bars
      // let startHue, midHue, endHue, startLight, midLight, endLight;
      let startHue = 360,
        midHue = 373,
        endHue = 330;
      let startLight = 54,
        midLight = 57,
        endLight = 49;
      let percentX = Math.floor(((i + 1) / duration) * 100); // 0 - 100
      let hue, lightness;
      // The gradient is orange 35% of the way in
      if (percentX <= 35) {
        hue = startHue + ((midHue - startHue) * percentX) / 100;
        lightness = startLight + ((midLight - startLight) * percentX) / 100;
      } else {
        hue = midHue + ((endHue - midHue) * percentX) / 100;
        lightness = midLight + ((endLight - midLight) * percentX) / 100;
      }
      cx.fillStyle = "hsl(" + hue + ", 100%, " + lightness + "%)";
      cx.beginPath();
      let barHeight;
      let barPos;
      if (current === "year") {
        if (monthlyCostData[i] !== undefined) {
          barPos = Number(monthlyCostData[i].monthOfYear) - 1;
          barHeight =
            0 - monthlyCostData[i].cost * (chartHeight / highestValue);
          startX = barPos * barWidth + barPos * barGap + canvasPadding / 2;
        }
      } else if (current === "month") {
        if (viewPeriod[i] !== undefined) {
          barPos = Number(viewPeriod[i].day) - 1;
          barHeight = 0 - viewPeriod[i].cost * (chartHeight / highestValue);
          startX = barPos * barWidth + barPos * barGap + canvasPadding / 2;
        }
      } else {
        // "all"
        barPos = i;
        barHeight = 0 - data[i].cost * (chartHeight / highestValue);
        startX = barPos * barWidth + barPos * barGap + canvasPadding / 2;
      }
      //   cx.roundRect(startX, startY, barWidth, barHeight, [0, 0, 3, 3]);
      let proportionalBarHeight = (barHeight * tallestBar) / chartHeight;
      cx.rect(startX, startY, barWidth, proportionalBarHeight);
      cx.fill();
    }

    // y-axis labels (costs)
    let labelWidth = cx.measureText("$000").width; // e.g. $100 (4 chars)
    // y-axis lines
    const lineStartX = canvasPadding / 2;
    const lineEndX = canvasWidth - canvasPadding / 2 - barGap; // - barGap removes 1px overhang
    let numYSteps = 4; // max number of lines drawn
    let yStep = topStep / numYSteps; // e.g. 125 = $500 / 4
    let division = chartHeight / Math.round(numYSteps);
    // Draw
    cx.strokeStyle = "#777";
    cx.lineWidth = 0.25;
    // A line across zero
    cx.beginPath();
    cx.moveTo(lineStartX, startY);
    cx.lineTo(lineEndX, startY);
    cx.stroke();
    // Additional lines
    for (
      let i = division, label = yStep;
      i <= chartHeight;
      i += division, label += yStep
    ) {
      cx.beginPath();
      cx.moveTo(lineStartX, startY - i);
      cx.lineTo(lineEndX, startY - i);
      cx.stroke();
      // y-axis label text
      // let labelEvery = 1; // e.g. 2 = label every second line
      // if (label % (yStep * labelEvery) === 0) {
      cx.fillStyle = "#0f172a";
      cx.fillText("$" + label, centerX - labelWidth / 2, startY - i + 3); // 3 to visually center vertically}
    }
  }, [
    current,
    currentMonth,
    data,
    monthlyCostData,
    // numOfDays,
    // shortMonthNames,
    viewPeriod,
  ]);
  const handleClickMonth = () => {
    setCurrentMonth(lastMonth); // ensure we don't land on an empty month, e.g jan '22
    setCurrentYear(lastYear);
    setCurrent("month");
  };
  const handleClickYear = () => {
    setCurrentYear(lastYear);
    setCurrent("year");
  };
  const handleClickAll = () => {
    setCurrent("all");
  };
  // Stats data
  const [totalCosts, setTotalCosts] = useState();
  const [averageCost, setAverageCost] = useState();
  useEffect(() => {
    let periodToSum;
    if (current === "year") {
      periodToSum = monthlyCostData;
    } else if (current === "month") {
      periodToSum = viewPeriod;
    } else {
      periodToSum = data;
    }
    setTotalCosts(
      periodToSum.reduce((accumulator, current) => {
        return accumulator + current.cost;
      }, 0)
    );
    let totalShops = periodToSum.length;
    setAverageCost(Math.floor(totalCosts / totalShops));
  }, [current, viewPeriod, data, monthlyCostData, totalCosts]);

  return (
    <>
      <div className="mt-6 btn-group-wrapper">
        <button
          className={`btn-group ${current === "month" && "is-current"}`}
          onClick={handleClickMonth}
        >
          Month
        </button>
        <button
          className={`btn-group ${current === "year" && "is-current"}`}
          onClick={handleClickYear}
        >
          Year
        </button>
        <button
          className={`btn-group ${current === "all" && "is-current"}`}
          onClick={handleClickAll}
        >
          All
        </button>
      </div>
      <div
        id="canvas-wrapper"
        className="relative aspect-[1/1] overflow-hidden"
      >
        <div className="mt-8 w-full absolute">
          <div className="px-3 mb-3 justify-between flex whitespace-nowrap items-center">
            <button
              onClick={handlePrev}
              className={`bg-transparent p-2 ${
                current === "all" && "invisible"
              } ${
                current === "month" &&
                currentMonth <= firstMonth &&
                currentYear <= firstYear &&
                "invisible"
              } ${
                current === "year" && currentYear <= firstYear && "invisible"
              }`}
            >
              <RiArrowLeftLine className="w-6 h-6" />
            </button>
            <h2 className="text-lg">{heading}</h2>
            <button
              onClick={handleNext}
              className={`bg-transparent p-2 ${
                current === "all" && "invisible"
              } ${
                current === "month" &&
                currentMonth >= lastMonth &&
                currentYear >= lastYear &&
                "invisible"
              } ${
                current === "year" && currentYear >= lastYear && "invisible"
              }`}
            >
              <RiArrowRightLine className="w-6 h-6" />
            </button>
          </div>
          {!isNaN(totalCosts) && totalCosts !== 0 && (
            <div className="-mt-1.5 tracking-wider haas-55 px-4 text-xs relative">
              <span>Total:</span>
              <span className="haas-75 absolute left-[4.75rem]">
                ${totalCosts.toLocaleString()}.00
              </span>
            </div>
          )}
          {!isNaN(averageCost) && (
            <div className="mt-0.5 tracking-wider haas-55 px-4 text-xs relative">
              <span>Average:</span>
              <span className="haas-75 absolute left-[4.75rem]">
                ${averageCost.toLocaleString()}.00
              </span>
            </div>
          )}
        </div>
        <canvas
          id="canvas"
          className="mt-6 w-full aspect-square"
          width="200"
          height="200"
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        />
      </div>
      <p className="text-xs mt-3 relative text-slate-600 leading-[1.125rem]">
        We respect your privacy and do not know your shopping history. It is
        stored locally in your browser and can be deleted below.
      </p>
    </>
  );
};

export default Chart;
