const PrettyDate = function () {
  const date = new Date();
  const dayNumber = date.getDay(); // 0-6
  const dayOfMonth = date.getDate(); // 1-31
  const monthNumber = date.getMonth(); // 0-11
  const days = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = days[dayNumber];
  const month = months[monthNumber];
  const prettyDate = day + ", " + dayOfMonth + " " + month;

  return prettyDate;
};

export default PrettyDate;
