import styled from "styled-components";

export const BubbleWrap = styled.div`
  &::after {
    position: absolute;
    left: calc(50% - 8px);
    bottom: -6px;
    content: "";
    display: block;
    width: 16px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 6px solid #0075fb;
  }
`;
