import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Slider from "./Slider";

export default function ModalSlider(props) {
  return (
    <Transition appear show={props.isOpenSlider} as={Fragment}>
      <Dialog
        onClose={props.closeModalSlider}
        as="div"
        className="relative z-10"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 modal-bg" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="modal w-full max-w-md transform overflow-hidden bg-[#fdfefb] text-left shadow-xl transition-all">
                {/* <Dialog.Title as="h2" className="modal-heading">
                  Create a Quick List
                </Dialog.Title> */}
                <>
                  <div className="h-8" />
                  <Slider
                    listLength={props.listLength}
                    storedItemsListLength={props.storedItemsListLength}
                    // storedAvgCost={props.storedAvgCost}
                    handleSlider={props.handleSlider}
                  />
                </>
                <div className="mt-8 flex space-x-4">
                  <button
                    className="btn btn-secondary w-1/2"
                    onClick={props.closeModalSlider}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn w-1/2"
                    onClick={() => {
                      props.handleSortByOrder();
                      props.closeModalSlider();
                    }}
                  >
                    Continue
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
