// Add to Home Screen (A2HS)
import React, { useState, useEffect } from "react";
import { RiDownload2Line } from "react-icons/ri";

function AtoHSButton() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
    setIsInstallable(true);
    setDeferredPrompt(event);
  };

  const handleClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <>
      {isInstallable && (
        <button
          className="footer-btn uppercase px-2 py-3.5"
          onClick={handleClick}
        >
          <RiDownload2Line className="w-5 h-5 text-[#00e8f2]" />
        </button>
      )}
    </>
  );
}

export default AtoHSButton;
