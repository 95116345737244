import React from "react";
import { BiCoffeeTogo } from "react-icons/bi";
import { RiShareLine } from "react-icons/ri";
import Tooltip from "./Tooltip";
import AtoHSButton from "./AtoHSButton";

const Footer = (props) => {
  return (
    <div
      className="footer py-1 text-center haas-55 text-xs pl-[18px] pr-[10px]"
      style={{
        background:
          "linear-gradient(135deg, rgba(0,15,42,1) 0%,rgba(0,0,42,1) 35%,rgba(0,15,0,1) 100%)",
      }}
    >
      <div className="footer-buttons flex justify-between items-center">
        <span className="flex text-[#0092b8]">2023 © JB and Reg</span>
        <div className="flex">
          <AtoHSButton />
          <div className="relative">
            <button
              className="footer-btn uppercase px-2 py-3.5"
              onClick={props.handleShareApp}
            >
              <RiShareLine className="w-5 h-5 text-[#00e8f2]" />
            </button>
            <Tooltip
              classNames="tooltip tooltip-above bottom-full left-[1.125rem]"
              style={{
                opacity: props.shareAppTooltipVis ? "1" : "0",
                transition: "all .15s",
                visibility: props.shareAppTooltipVis ? "visible" : "hidden",
              }}
            >
              Share&nbsp;link
              <br />
              copied
            </Tooltip>
          </div>
          <a
            className="footer-btn uppercase px-2 py-3.5"
            href="https://ko-fi.com/blist"
          >
            <BiCoffeeTogo className="w-5 h-5 text-[#00e8f2]" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
