import React from "react";

const Emoji = (props) => {
  let data = require("./emojis.json");

  const findEmoji = (label) => {
    // Initial search
    let searchString = data.find((ourEmoji) =>
      ourEmoji.aliases.includes(label)
    );
    // If no match is found, split the label into words and search for each word
    if (!searchString) {
      const words = label.split(" ");
      for (let word of words) {
        searchString = data.find((ourEmoji) => ourEmoji.aliases.includes(word));
        if (searchString) {
          break;
        }
      }
    }
    return searchString;
  };

  const searchString = findEmoji(props.label);
  // Vary the placeholder emoji
  const dayNumber = new Date().getDay() + 1; // 1-7

  return (
    <span
      className="emoji absolute text-xl"
      role="img"
      aria-label={props.label ? props.label : ""}
      aria-hidden={props.label ? "false" : "true"}
    >
      {/* {searchString ? searchString.emoji : data[dayNumber].emoji} */}
      {searchString
        ? searchString.emoji
        : props.input
        ? data[0].emoji
        : data[dayNumber].emoji}
    </span>
  );
};

export default Emoji;
